import { Context, createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useCurrentUserQuery } from 'generated/hooks';
import { CurrentUserType } from 'types';
import { identifyUser } from 'app/_tracking';
import { UserNode } from '../generated/types';
import { UseQueryExecute, UseQueryState } from 'urql';
import { CurrentUserQuery } from '../generated/operations';

type AuthContextType = {
  currentUser?: UserNode | null;
  checkIsCurrentUser: (userId: string) => boolean;
  currentUserState: UseQueryState<CurrentUserQuery> | null;
  setCurrentUser: (user: UserNode) => void;
  reFetchCurrentUser: UseQueryExecute,
};

const defaultValues: AuthContextType = {
  currentUser: null,
  currentUserState: null,
  checkIsCurrentUser: () => false,
  setCurrentUser: () => {},
  reFetchCurrentUser: () => void 0,
};

export const AuthContext: Context<AuthContextType> = createContext(defaultValues);

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [res, reFetchCurrentUser] = useCurrentUserQuery();

  const [currentUser, setCurrentUserRaw] = useState(() => {
    return res?.data?.currentUser
  });

  const setCurrentUser = (user: UserNode) => {
    setCurrentUserRaw(user);
    identifyUser(user);
  };

  useMemo(() => {
    if (!res.data?.currentUser) return null;
    setCurrentUser(res.data.currentUser);
    return res.data;
  }, [res]);

  const checkIsCurrentUser = (userId: string) => {
    return currentUser?.id === userId;
  };

  const currentUserState: UseQueryState<CurrentUserQuery> = useMemo(() => res, [res])

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        currentUserState,
        checkIsCurrentUser,
        setCurrentUser,
        reFetchCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
