'use client';

import { Context, createContext, FC, ReactNode, useEffect, useMemo } from 'react';
import { usePathname } from 'next/navigation';

import { useRouteListener } from 'hooks/useRouteListener';

interface IRouteListenerProvider {
  children: ReactNode;
}

interface IRouteListenerContext {
  routesList: string[];
  handleSetRoute: (pathname: string) => void;
}

const defaultValues: IRouteListenerContext = {
  routesList: [],
  handleSetRoute: () => void 0,
};

const RouteListenerContext: Context<IRouteListenerContext> = createContext(defaultValues);

export const RouteListenerProvider: FC<IRouteListenerProvider> = (props) => {
  const pathname = usePathname();

  const {
    routesList,
    handleSetRoute,
  } = useRouteListener();

  useEffect(() => {
    handleSetRoute(pathname as string);
  }, [handleSetRoute, pathname]);

  const value = useMemo(() => ({
    routesList,
    handleSetRoute,
  }), [handleSetRoute, routesList]);

  return (
    <RouteListenerContext.Provider value={value}>
      {props.children}
    </RouteListenerContext.Provider>
  );
};

export default RouteListenerContext;
