import { useCallback, useState } from 'react';

export const useRouteListener = () => {
  const [routesList, setList] = useState<string[]>([]);

  const handleSetRoute = useCallback((pathname: string) => {
    setList((prevState) => {
      if (prevState[prevState.length - 1] !== pathname) {
        return [...prevState, pathname];
      }
      return prevState;
    })
  }, []);

  return {
    routesList,
    handleSetRoute,
  };
};
